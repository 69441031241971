export default {
  product_prices: {
    country_from_DE: 'Besuchst du uns aus {country}?',
    country_from_EN: 'Are you visiting us from {country}?',
    country_from_ES: '¿Nos visitas desde {country}?',
    country_from_FR: 'Nous rends-tu visite depuis le {country} ?',
    country_from_IT: 'Ci stai visitando dal {country}?',
    country_from_PT: 'Você está visitando de {country}?',
    country_from_NL: "Bezoek je ons vanuit {country}?",


    country_change_DE: 'Wähle deine Geolokalisierung',
    country_change_EN: 'Choose your location',
    country_change_ES: 'Elige tu ubicación',
    country_change_FR: 'Choisissez votre géolocalisation',
    country_change_IT: 'Scegli la tua geolocalizzazione',
    country_change_PT: 'Escolha sua localização geográfica',
    country_change_NL: "Kies je locatie"
  },
  countries_DE: {
    now: 'You are in',
    change: 'Change',
    name: {
      ES: 'Spain',
      FR: 'France',
      DE: 'Germany',
      IT: 'Italy',
      UK: 'Great Britain',
      MX: 'Mexico',
      PT: 'Portugal',
      EUR: 'Europe',
      AME: 'America',
      NL: 'Niederlande'
    }
  },
  countries_EN: {
    now: 'Sie befinden sich in',
    change: 'Ändern',
    name: {
      ES: 'Spanien',
      FR: 'Frankreich',
      DE: 'Deutschland',
      IT: 'Italien',
      UK: 'Großbritannien',
      MX: 'Mexiko',
      PT: 'Portugal',
      EUR: 'Europa',
      AME: 'Amerika',
      NL: 'Netherlands'
    }
  },
  countries_ES: {
    now: 'Estás en',
    change: 'Cambiar',
    name: {
      ES: 'España',
      FR: 'Francia',
      DE: 'Alemania',
      IT: 'Italia',
      UK: 'Gran Bretaña',
      MX: 'México',
      PT: 'Portugal',
      EUR: 'Europa',
      AME: 'América',
      NL: 'Países Bajos'
    }
  },
  countries_FR: {
    now: 'Tu es en',
    change: 'Changer',
    name: {
      ES: 'Espagne',
      FR: 'France',
      DE: 'Allemagne',
      IT: 'Italie',
      UK: 'Grande Bretagne',
      MX: 'Mexique',
      PT: 'Portugal',
      EUR: 'Europe',
      AME: 'Amérique',
      NL: 'Pays-Bas'
    }
  },
  countries_IT: {
    now: 'Ci sei',
    change: 'Modifica',
    name: {
      ES: 'Spagna',
      FR: 'Francia',
      DE: 'Germania',
      IT: 'Italia',
      UK: 'Gran Bretagna',
      MX: 'Messico',
      PT: 'Portogallo',
      EUR: 'Europa',
      AME: 'America',
      NL: 'Paesi Bassi'
    }
  },
  countries_PT: {
    now: 'Você está em',
    change: 'Alterar',
    name: {
      ES: 'Espanha',
      FR: 'França',
      DE: 'Alemanha',
      IT: 'Itália',
      UK: 'Reino Unido',
      MX: 'México',
      PT: 'Portugal',
      EUR: 'Europa',
      AME: 'América',
      NL: 'Holanda'
    }
  },
  countries_NL: {
    now: "Je bent in",
    change: "Wijzig",
    name: {
      ES: "Spanje",
      FR: "Frankrijk",
      DE: "Duitsland",
      IT: "Italië",
      UK: "Groot-Brittannië",
      MX: "Mexico",
      PT: "Portugal",
      EUR: "Europa",
      AME: "VS",
      NL: "Nederland"
    }
  }
}
