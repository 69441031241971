//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import AxiApi from '~/lib/axi'
import projectData from '~/static/config/project.json'
import global from '~/mixins/global.js'
import * as textFunctions from '~/mixins/text.functions.js'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'
import { filtersUrlIsIndexable } from '~/lib/seo'

export default {
  // amp: 'hybrid',
  // ampLayout: 'default.amp',
  components: {
    LazyHydrate,
    ModuleProductFeatured: () => import('~/components/ModuleProductFeatured.vue'),
    ModuleProductPriceOpinion: () =>
      import('~/components/ModuleProductPriceOpinion.vue'),
    ModuleReview: () => import('~/components/ModuleReview.vue'),
    ModuleTexto: () => import('~/components/ModuleTexto.vue')
  },
  mixins: [global],
  middleware: ['Filters'],
  async asyncData ({ params, route, app, store, error, $axios, $config, $md5 }) {
    // Params & Props
    const routeProps = route.matched[0].props.default
    const TypeId = routeProps.prodTypeId
    let productType = Object.values(projectData.contents.product_types).find(
      pt => pt.id === TypeId
    )
    if (!productType) {
      productType = Object.values(projectData.contents.product_types_marketplaces).find(
        pt => pt.id === TypeId
      )
      if (productType) {
        productType.marketplace = true
      } else {
        productType = Object.values(projectData.contents.product_types_marketplaces).find(
          pt => pt.id === '0'
        )
      }
    } else {
      productType.marketplace = false
    }

    if (productType && productType.filters) {
      productType.filters = productType.filters.map(filter => {
        if (
          filter.share &&
          projectData.filters_share &&
          projectData.filters_share[filter.share]
        ) {
          filter = { ...projectData.filters_share[filter.share], ...filter }
        }
        return filter
      })
      productType.filters.filter(filter => {
        return filter
      })
    }

    productType.paths.products =
        productType.paths.products ||
        projectData.contents.product_types.products.paths.products
    productType.paths.products_all =
        productType.paths.products_all ||
        projectData.contents.product_types.products.paths.products_all

    // Busqueda texto
    let querySearch = ''
    if (typeof route.query.q !== 'undefined' && route.query.q !== '') {
      querySearch = route.query.q
    }

    // Ordenes
    let order = ''
    if (querySearch !== '' && !route.query.order) {
      order = '&order=views_30&direction=desc'
    } else if (route.query.order) {
      const lastUnderscore = route.query.order.lastIndexOf('_')
      order = `&order=${route.query.order.substr(
          0,
          lastUnderscore
        )}&direction=${route.query.order.substr(lastUnderscore + 1)}`
    }
    // Zones & Heritages
    const ax = new AxiApi($config.api)
    const call =
        (TypeId &&
          !isNaN(TypeId) &&
          TypeId > 0 &&
          `${$config.static}/json/zones/products/${TypeId}/zones.json`) ||
        $config.static + '/json/zones/products/zones.json'
    let zones
    try {
      zones = await $axios.get(call)
    } catch (err) {
      return error({ statusCode: 404, message: err.message })
    }
    await ax.createComponentData(zones.data, store)

    // Get API data
    let page = 1
    const filters = {}
    const filtersSEO = {}
    let filters2api = ''
    const filters2seo = {}
    const selectedBrands = []

    if (typeof params.marca !== 'undefined') {
      const urlBrands = params.marca.split(',')

      urlBrands.forEach(e => {
        if (store.state.brands.find(b => b.id === e)) {
          selectedBrands.push(store.state.brands.find(b => b.id === e))
        }
      })
      filters.brand = params.marca
    }

    if (params.pathMatch && params.pathMatch !== '') {
      let filtersUrl = params.pathMatch

      if (filtersUrl.match(/^(.*?\/)?([0-9]+)\/?$/)) {
        const pathMatches = filtersUrl.match(/^(.*?\/)?([0-9]+)\/?$/)
        page = pathMatches[2]
        filtersUrl = pathMatches[1] !== undefined ? pathMatches[1] : ''
      }
      try {
        filtersUrl.split('/').forEach(element => {
          if (element !== '') {
            const matches = element.match(/^([^-]+)-(.*?)$/)
            let filtroId = ''
            let filtroValues = []
            const filtroValuesSEO = []
            if (matches) {
              filtroId = matches[1]
              filtroValues = matches[2].split(',')
            }

            const filtroConfig = productType.filters.find(
              f => (f?.url || f?.name) === filtroId
            )

            if (filtroConfig?.values) {
              const newFiltroValues = []
              filtroValues.forEach(v => {
                const filtroV = filtroConfig.values.find(
                  fv => (fv.url || fv.name) === v
                )
                newFiltroValues.push(filtroV.id)
                filtroValuesSEO.push(filtroV.seo || filtroV.name)
              })
              filtroValues = newFiltroValues
            }
            if (filtroConfig?.id) {
              filters[filtroConfig.id] = filtroValues.join(',')
            }
            if (filtroValuesSEO.length > 0 && filtroConfig?.id) {
              filtersSEO[filtroConfig.id] = filtroValuesSEO
            }
          }
        })
      } catch (err) {
        return error({ statusCode: 404, message: err.message })
      }
    }

    // Dinamic SEO
    const selectedBrand = selectedBrands.length === 1 ? selectedBrands[0] : null
    if (selectedBrands.length > 0) {
      filters.brand = ''
      filtersSEO.brand = []
      selectedBrands.forEach((b, i) => {
        filters.brand += `${i > 0 ? ',' : ''}${b.id}`
        filtersSEO.brand.push(b.name)
      })
    }

    // Ordenando filtros
    if (productType?.filters) {
      productType.filters.forEach(filter => {
        if (filters[filter.id]) {
          filters2api += '/' + filter.id + '-' + filters[filter.id]
          filtersSEO[filter.id] = (filter.seo || '{value}').replace(
            '{value}',
            (filtersSEO[filter.id] && filtersSEO[filter.id].join(', ')) ||
                filters[filter.id]
          )
        } else if (
          filter.default?.products?.seo &&
            (!filter.default?.products?.filters ||
              (filter.default?.products.filters === 'no' &&
                Object.keys(filters).length === 0) ||
              (filter.default?.products.filters === 'yes' &&
                Object.keys(filters).length > 0))
        ) {
          filtersSEO[filter.id] = filter.default.products.seo
        }
      })
    }

    if (
      route.query.order &&
      projectData.contents.product_types.products.metas.products.sorts[route.query.order] &&
      projectData.contents.product_types.products.metas.products.sorts[route.query.order]
        .seo
    ) {
      filtersSEO.sort =
      projectData.contents.product_types.products.metas.products.sorts[
        route.query.order
      ].seo
    }

    if (querySearch !== '') {
      filters.text = `'${querySearch}'`
    }

    let productTypeMetas = productType?.metas.products
    if (productType?.metas?.products) {
      let filtersMetas = false
      Object.entries(filters).forEach(([filter, filterValue]) => {
        const filterObj = productType.filters.find(ptFilter => ptFilter.id === filter)
        if (filterObj) {
          const filterValueObj =
              filterObj.values &&
              filterObj.values.find(ptFilterValue => ptFilterValue.id === filterValue)
          if (filterValueObj && filterValueObj.metas?.products) {
            filtersMetas =
                (filtersMetas === false && {
                  ...productType.metas.products,
                  ...filterValueObj.metas.products
                }) ||
                false
          }
        }
      })
      productTypeMetas = filtersMetas || productType.metas.products

      if (productTypeMetas.filters) {
        Object.keys(productTypeMetas.filters).forEach(filtersID => {
          filters2seo[filtersID] = ''
          productTypeMetas.filters[filtersID].forEach(filterID => {
            const filter2seo = filtersSEO[filterID] || filters[filterID] || ''
            filters2seo[filtersID] += (filter2seo && ' ' + filter2seo) || ''
          })
          filters2seo[filtersID] = filters2seo[filtersID]
            .trim()
            .replace(/,/g, ', ')
            .replace(/ +/, ' ')
          filters2seo[filtersID] =
              filters2seo[filtersID] && ' ' + filters2seo[filtersID]
        })
      }
    }

    const query = `${$config.api}/app/v1/products/list?_p=${$config.id}&products_type=${TypeId}&filters=${filters2api}&text=${querySearch}&page=${page}${order}&scope=productos${querySearch !== '' ? '&contents=5' : ''}&advertising=1`
    const code = $md5(query)
    if (
      typeof store.state.lists[code] === 'undefined' ||
        Object.keys(store.state.lists[code]).length === 0
    ) {
      try {
        await store.dispatch('loadListsError', query)
      } catch (err) {
        return error({ statusCode: 404, message: err.message })
      }
    }

    let productList = {}
    productList.contents = []
    if (typeof store.state.lists[code] === 'object') {
      productList = store.state.lists[code]
    }

    // Article List para texto libre
    let articleList = {}
    if (querySearch !== '') {
      const queryArticles = `${$config.api}/app/v1/articles/list?_p=${$config.id}&text=${querySearch}&contents=5`
      const codeArticles = $md5(queryArticles)
      if (
        typeof store.state.lists[codeArticles] === 'undefined' ||
      Object.keys(store.state.lists[codeArticles]).length === 0
      ) {
        try {
          await store.dispatch('loadLists', queryArticles)
        } catch (err) {
          return error({ statusCode: 404, message: err.message })
        }
      }
      articleList = store.state.lists[codeArticles]
    }

    // Structured Data
    const structuredDataProduct = []
    const structuredDataCarrouselItems = []
    productList.contents.forEach(function (product, index) {
      if (product.price?.lower && (product.price.lower > 0) && (product.type !== 'AdvertisingProductsCard')) {
        const pathMatches = product.path.match(
          /^\/json\/products\/([0-9]+)(.+?)index.json$/
        )
        const productTypeList = Object.values(projectData.contents.product_types).find(
          pt => pt.id === pathMatches[1]
        )
        const prodUrl =
            (productTypeList?.paths?.home &&
              `/${productTypeList.paths.home}${pathMatches[2]}`) ||
            `/${pathMatches[2]}`
        let itemProduct = {}
        let itemCarrousel = {}
        itemProduct = {
          '@context': 'https://schema.org/',
          '@type': 'Product',
          name: `${product.name}`,
          url: `${$config.domain}${prodUrl}`,
          image: [
              `${product.photo?.large ? product.photo.large.src : product.photo.src}`
          ],
          brand: {
            '@type': 'Brand',
            name: product.brand
          },
          offers: {
            '@type': 'AggregateOffer',
            lowPrice: `${product.price.lower}`,
            highPrice: `${product.price.real}`,
            priceCurrency: 'EUR'
          }
        }
        if (product.review > 0) {
          itemProduct.aggregateRating = {
            '@type': 'AggregateRating',
            bestRating: 5,
            ratingValue: (product.review / 2).toFixed(1),
            reviewCount: 1
          }
        }
        itemCarrousel = {
          '@type': 'ListItem',
          position: index + 1,
          url: `${$config.domain}${prodUrl}`
        }

        structuredDataProduct.push(itemProduct)
        structuredDataCarrouselItems.push(itemCarrousel)
      }
    })

    const structuredCarrousel = {
      '@context': 'https://schema.org/',
      '@type': 'ItemList',
      itemListOrder: 'http://schema.org/ItemListOrderAscending',
      itemListElement: structuredDataCarrouselItems
    }

    // Metas & Head
    const listSEO = productTypeMetas
    productList.results = textFunctions.textNormalize(
      (listSEO.results || `${productList.total} ${productType.name.toLowerCase()}`)
        .replace(/{filters:(.+?)}/g, function (_str, filterGroup) {
          return (
            (filters2seo[filterGroup] && filters2seo[filterGroup].replace('-', ' ')) ||
              ''
          )
        })
        .replace(/{total}/g, productList.total)
    )
    let pageTitle = ''
    if (querySearch === '') {
      pageTitle = textFunctions.textNormalize(
        (listSEO.title || app.i18n.t('product_list.page_title')).replace(
          /{filters:(.+?)}/g,
          function (_str, filterGroup) {
            return (
              (filters2seo[filterGroup] && filters2seo[filterGroup].replace('-', ' ')) ||
              ''
            )
          }
        )
      )
    } else if (querySearch !== '' && productList.contents.length > 0) {
      pageTitle = textFunctions.textNormalize(`${app.i18n.t('product_list.search_product_title')} '${querySearch}'`)
    } else if (querySearch !== '' && articleList.contents.length > 0) {
      pageTitle = textFunctions.textNormalize(`${app.i18n.t('product_list.search_article_title')} '${querySearch}'`)
    } else {
      pageTitle = textFunctions.textNormalize(`${app.i18n.t('product_list.search_title')} '${querySearch}'`)
    }

    const metaDescription = textFunctions.textNormalize(
      (listSEO.description || app.i18n.t('product_list.meta_description')).replace(
        /{filters:(.+?)}/g,
        function (_str, filterGroup) {
          return filters2seo[filterGroup] || ''
        }
      )
    )
    const header = {
      h1: `${pageTitle}${
          page > 1 ? ` - ${app.i18n.t('product_list.pag')} ${page}` : ''
        }`
    }
    const tagline =
        productType.metas.tagline ||
        projectData.contents.product_types.products?.metas?.tagline
    const head = {
      title: `${pageTitle}${
          page > 1 ? `, ${app.i18n.t('product_list.pag')} ${page}` : ''
        }${(tagline && ' - ' + tagline) || ''} | ${projectData.name}`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: `${pageTitle}${
              page > 1 ? `, ${app.i18n.t('product_list.pag')} ${page}` : ''
            }${(tagline && ' - ' + tagline) || ''} | ${projectData.name}`
        },
        {
          hid: 'description',
          name: 'description',
          content: `${metaDescription}`
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: `${$config.siteName}`
        },
        {
          hid: 'og:url',
          name: 'og:url',
          content: `${$config.domain}${route.path}`
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `${metaDescription}`
        },
        {
          hid: 'og:type',
          name: 'og:type',
          content: 'website'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: `${pageTitle}${
              page > 1 ? `, ${app.i18n.t('product_list.pag')} ${page}` : ''
            }${(tagline && ' - ' + tagline) || ''} | ${projectData.name}`
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: `${$config.locale}`
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `${$config.domain}${route.path
              .replace(route.params.pathMatch, route.params.pathMatch + '/')
              .replace('//', '/')}`
        }
      ],
      script: []
    }

    const urlIndexable = (Object.keys(route.query).length === 0) && filtersUrlIsIndexable(productList?.filters?.active, productType, 'products')

    if (!urlIndexable) {
      head.meta.push({
        hid: 'robots',
        name: 'robots',
        content: 'noindex, follow'
      })
    }

    structuredDataProduct.forEach(element => {
      head.script.push({
        type: 'application/ld+json',
        json: element
      })
    })

    if (structuredDataCarrouselItems.length > 0) {
      head.script.push({
        type: 'application/ld+json',
        json: structuredCarrousel
      })
    }

    if (
      productList.structured_data &&
        Object.keys(productList.structured_data).length > 0
    ) {
      head.script.push({
        type: 'application/ld+json',
        json: productList.structured_data[0]
      })
    }

    // Módulo sobre los que los usuarios hablan - Solo con 1 tipo de producto
    const talkingAboutQry = `${$config.api}/app/v1/products/list?_p=${$config.id}&products_type=${TypeId}&contents=8&order=users&direction=desc`
    const talkingAboutCode = $md5(talkingAboutQry)
    if (
      typeof store.state.modules[talkingAboutCode] === 'undefined' ||
        Object.keys(store.state.modules[talkingAboutCode]).length === 0
    ) {
      await store.dispatch('loadModules', talkingAboutQry)
    }
    const talkingAboutContents = store.state.modules[talkingAboutCode]

    // Módulo reviews - Solo con 1 tipo de producto
    const reviewsModuleQry = `${$config.api}/app/v1/reviews/list?_p=${
        $config.id
      }&filters=${!isNaN(TypeId) && TypeId ? `/product_type-${TypeId}` : ''}`
    const reviewsModuleCode = $md5(reviewsModuleQry)
    if (
      typeof store.state.modules[reviewsModuleCode] === 'undefined' ||
        Object.keys(store.state.modules[reviewsModuleCode]).length === 0
    ) {
      await store.dispatch('loadModules', reviewsModuleQry)
    }
    const reviewsModuleContents = store.state.modules[reviewsModuleCode]

    const extraModulesTop = []
    const urlPosition = route.fullPath.search(`${productType.paths.products_all}`)
    const subUrl = route.fullPath.slice(urlPosition)

    const topSalesFilters = {
      brand: [],
      gender: ['man', 'woman'],
      surface: ['trail']
    }
    let topSalesFiltersApi = ''
    let topSalesFiltersTitle = ''
    Object.entries(filters).some(([filterId, filterValuesText]) => {
      const filterValues = filterValuesText.split(',')
      let filterValuesOk = true
      if (topSalesFilters[filterId] && topSalesFilters[filterId].length > 0) {
        filterValues.some(value => {
          if (!topSalesFilters[filterId].includes(value)) {
            filterValuesOk = false
            return true
          } else {
            return false
          }
        })
      }
      if (!topSalesFilters[filterId] || !filterValuesOk) {
        topSalesFiltersApi = ''
        return true
      } else {
        topSalesFiltersApi += '/' + filterId + '-' + filterValuesText
        topSalesFiltersTitle += ' ' + filtersSEO[filterId]
        return false
      }
    })

    // Módulo similares

    const similarQry = `${$config.api}/app/v1/products/comparison?_p=${$config.id}&products_type=${TypeId}`
    const similarCode = $md5(similarQry)
    if (
      typeof store.state.lists[similarCode] === 'undefined' ||
      Object.keys(store.state.lists[similarCode]).length === 0
    ) {
      await store.dispatch('loadLists', similarQry)
    }

    const similarContents = store.state.lists[similarCode]
    const similarProductsFinal = []
    const similarProductsIDs = []

    for (let i = 0; i < similarContents.contents.length; i++) {
      if (!similarProductsIDs.includes(similarContents.contents[i].prd_1.content_id)) {
        similarProductsIDs.push(similarContents.contents[i].prd_1.content_id)
        similarProductsFinal.push(similarContents.contents[i].prd_1)
      }
      if (!similarProductsIDs.includes(similarContents.contents[i].prd_2.content_id)) {
        similarProductsIDs.push(similarContents.contents[i].prd_2.content_id)
        similarProductsFinal.push(similarContents.contents[i].prd_2)
      }
    }

    // Módulo más vendidos de la semana
    const topSalesQry = `${$config.api}/app/v1/products/list?_p=${$config.id}&products_type=${TypeId}&contents=8&order=sales_90&filters=${topSalesFiltersApi}`
    const topSalesCode = $md5(topSalesQry)
    if (
      typeof store.state.modules[topSalesCode] === 'undefined' ||
        Object.keys(store.state.modules[topSalesCode]).length === 0
    ) {
      await store.dispatch('loadModules', topSalesQry)
    }
    const topSalesContents = store.state.modules[topSalesCode]

    if (
      topSalesContents.contents.length > 4 &&
        (subUrl === `${productType.paths.products_all}/` ||
          subUrl.includes('gt_origen') ||
          topSalesFiltersApi)
    ) {
      extraModulesTop.push({
        template: 'ModuleProductFeatured',
        data: topSalesContents.contents,
        elements: {
          brand: true,
          name: true,
          photo: true,
          price: true,
          photoGen: filters.gender && filters.gender.split(',')
        },
        generic: {
          header: {
            text:
            projectData.id === 21
              ? `${
                      (productType?.gender &&
                      projectData.contents.product_types.products?.texts?.more_solded &&
                        projectData.contents.product_types.products.texts.more_solded[
                          productType.gender
                        ] &&
                        projectData.contents.product_types.products.texts.more_solded[
                          productType.gender
                        ]
                          .charAt(0)
                          .toUpperCase() +
                          projectData.contents.product_types.products.texts.more_solded[
                            productType.gender
                          ].slice(1)) + ' ' || ''
                    }${topSalesFiltersTitle} ${
                      productType?.name.toLowerCase() ||
                      app.i18n.t('product_list.products').toLowerCase()
                    }`
              : `${
                      productType?.name || app.i18n.t('product_list.products')
                    }${topSalesFiltersTitle}${
                      (productType?.gender &&
                      projectData.contents.product_types.products?.texts?.more_solded &&
                      projectData.contents.product_types.products.texts.more_solded[
                          productType.gender
                        ] &&
                        ' ' +
                        projectData.contents.product_types.products.texts.more_solded[
                            productType.gender
                          ]) ||
                      ''
                    }`
          },
          footer: false
        },
        type: 'ProductCard',
        preload: true
      })
    }
    const extraModulesBottom = []
    if (productList.moduleText && Object.keys(productList.moduleText).length > 0) {
      extraModulesBottom.push({
        template: productList.moduleText.ConfigurationFile.template,
        data: productList.moduleText.ConfigurationFile.content.info
      })
    }
    if (filters.brand && talkingAboutContents?.contents?.length) {
      extraModulesBottom.push({
        template: 'ModuleProductPriceOpinion',
        data: talkingAboutContents.contents,
        elements: {
          brand: true,
          name: true,
          photo: true
        },
        generic: {
          header: {
            text: app.i18n.t(
              'product_list.module_products_opinion_' +
                  (productType?.gender || 'female'),
              {
                products: productType?.name || app.i18n.t('product_list.products')
              }
            )
          },
          footer: []
        },
        type: 'ProductCardPriceOpinion'
      })
    } else if (reviewsModuleContents?.contents?.length) {
      extraModulesBottom.push({
        template: 'ModuleReview',
        data: reviewsModuleContents.contents.slice(0, 4),
        elements: {},
        generic: {
          header: {
            text: app.i18n.t('product_list.module_reviews', {
              products: productType?.name || app.i18n.t('product_list.products')
            })
          },
          footer: []
        },
        type: 'ReviewCard'
      })
    }
    if (productList.hreflang) {
      productList.hreflang.forEach(version => {
        if (version.lang === 'es') {
          head.link.push({
            rel: 'alternate',
            hreflang: version.lang,
            href: version.url
          })
          head.link.push({
            rel: 'alternate',
            hreflang: 'x-default',
            href: version.url
          })
        } else {
          head.link.push({
            rel: 'alternate',
            hreflang: version.lang,
            href: version.url
          })
        }
      })
    }

    const sorting = []
    Object.keys(projectData.contents.product_types.products.metas.products.sorts).forEach(
      sort => {
        sorting.push({
          value: sort,
          text: projectData.contents.product_types.products.metas.products.sorts[sort].text
        })
      }
    )

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'product_list', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0,
        extraModulesTop: extraModulesTop.length ? 1 : 0
      })
    }

    return {
      zones: zonesContent,
      productList,
      articleList,
      path: route.path,
      productType,
      querySearch,
      head,
      header,
      sorting,
      order:
          querySearch !== '' && !route.query.order
            ? 'views_30_desc'
            : route.query.order
              ? route.query.order
              : productList.order.id + '_' + productList.order.direction,
      extraModulesTop,
      extraModulesBottom,
      similarProductsFinal,
      topSalesContents,
      brand: selectedBrand,
      urlIndexable
    }
  },
  data () {
    return {
      bestOffer: false,
      order: null,
      // imagenes
      chevronDown: {
        width: 10,
        height: 24,
        class: 'icon',
        alt: 'order',
        src: require('@/assets/img/icons/icon-chevron-down-grey.svg')
      },
      filters: []
    }
  },
  head () {
    return this.head
  },
  computed: {
    brands () {
      let filteredBrands = []
      filteredBrands = this.$store.state.brands.filter(brand => {
        return brand.products.length > 0 && brand.name === this.brand
      })
      return filteredBrands
    }
  },
  watchQuery: ['q'],
  beforeMount () {
    if (this.productList?.filters?.view) {
      this.filters = JSON.parse(JSON.stringify(this.productList.filters.view))

      this.filters.map((filter) => {
        const filterMapped = filter

        if (filterMapped.values) {
          filterMapped.values.map((value) => {
            const valueMapped = value

            valueMapped.finalUrl = this.create_filter_url(
              filter.id,
              value.id,
              this.productList.filters.url,
              this.productType,
              'products',
              '',
              this.$route.query
            )

            const customFiltersActive = JSON.parse(JSON.stringify(this.productList.filters.active || {}))

            if (customFiltersActive[filter.id]) {
              Object.keys(customFiltersActive).forEach((filterID) => {
                if (filterID === filter.id) {
                  if (customFiltersActive[filterID].includes(value.id)) {
                    customFiltersActive[filterID] = customFiltersActive[filterID].filter((filterValue) => {
                      return filterValue !== value.id
                    })
                  } else {
                    customFiltersActive[filterID].push(value.id)
                  }
                }
              })
            } else {
              customFiltersActive[filter.id] = [value.id]
            }

            valueMapped.nofollow = (Object.keys(this.$route.query).length > 0) || !filtersUrlIsIndexable(customFiltersActive, this.productType, 'products')

            return valueMapped
          })
        }

        return filterMapped
      })
    }
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  },
  methods: {
    changeOrder (val) {
      const pathArr = this.$route.fullPath.match(/(^[^?]+)\??(.+$)?/)
      let path = pathArr[1]
      let qryStr = pathArr[2] || ''
      // Tratar path
      if (path.match(/^(.*?\/)?([0-9]+)\/?$/)) {
        path = path.match(/^(.*?\/)?([0-9]+)\/?$/)[1]
      }
      // Tratar qryStr
      if (qryStr !== '') {
        if (qryStr.match(/order=[^&?]+/)) {
          qryStr = qryStr.replace(/order=[^&?]+/, `order=${val}`)
        } else {
          qryStr += '&order=' + val
        }
      }
      if (qryStr === '') {
        qryStr = 'order=' + val
      }
      const newUrl = projectData.domains.www.base + path + '?' + qryStr
      window.location = newUrl.replace('//', '/')
    },
    articleSearch () {
      this.$router.push(`/${projectData.contents.articles.all.paths.home}/?q=${this.querySearch}`)
    }
  }
}
