//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { customPageView } from '~/lib/gtm'
import global from '~/mixins/global'
import project from '~/static/config/project.json'
import { VBPopover } from 'bootstrap-vue'

export default {
  directives: {
    'b-popover': VBPopover
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  layout: 'redirecting',
  data () {
    const head = {
      title: 'Runnea - Redirect'
    }
    return {
      url: '',
      prevRoute: null,
      head,
      itemInfo: null,
      tracking: null,
      startTracking: 0,
      timerRedirect: null,
      global,
      country: project.country,
      copiedCoupon: ''
    }
  },
  head () {
    return this.head
  },
  beforeMount () {
    const ccCookie = this.$cookiz.get('cc_runnea')
    if (ccCookie && ccCookie.categories.includes('analytics')) {
      this.$gtm && this.$gtm.push({ event: 'ConsentGrantedAnalytics' })
    }
    customPageView.apply(this, [this.head.title], false, false)
  },
  mounted () {
    this.itemInfo = JSON.parse(localStorage.getItem('itemInfo'))
    localStorage.removeItem('itemInfo')

    const trackEvent = localStorage.getItem('trackEvent')
    localStorage.removeItem('trackEvent')

    const gtOrigen = this.$cookiz.get('gt_origen')
    const gtGclid = this.$cookiz.get('gt_gclid')
    const apiBaseUrl = this.$config.api + '/app/v1/prices/tracking'

    let apiQryStr = `_p=${this.$config.id}&cod=` + this.$route.query.cod
    apiQryStr += (this.$route.query.pos) ? '&pos=' + encodeURIComponent(this.$route.query.pos) : ''
    apiQryStr += (this.$route.query.org) ? '&org=' + encodeURIComponent(this.$route.query.org) : ''
    apiQryStr += (this.$route.query.at) ? '&at=' + encodeURIComponent(this.$route.query.at) : ''
    apiQryStr += '&url_origen=' + encodeURIComponent(this.prevRoute?.path)
    apiQryStr += gtOrigen ? '&source=' + gtOrigen : ''
    apiQryStr += gtGclid ? '&gclid=' + gtGclid : ''

    // User Agent
    const d = this.$device
    let so = 'unknown'
    if (d.isAndroid) { so = 'android' } else if (d.isIos) { so = 'ios' } else if (d.isWindows) { so = 'windows' } else if (d.isMacOS) { so = 'macos' }
    let browser = 'unknown'
    if (d.isChrome) { browser = 'chrome' } else if (d.isSafari) { browser = 'safari' } else if (d.isEdge) { browser = 'edge' } else if (d.isFirefox) { browser = 'firefox' } else if (d.isCrawler) { browser = 'crawler' }
    let device = 'unknown'
    if (d.isMobile) { device = 'mobile' } else if (d.isDesktop) { device = 'desktop' } else if (d.isTablet) { device = 'tablet' }

    // Params Click
    apiQryStr += '&prod_categoria=' + (this.itemInfo?.price.product.type_name || 'unknown')
    apiQryStr += '&prod_nombre=' + (this.itemInfo?.price.product.name || 'unknown')
    apiQryStr += '&prod_marca=' + (this.itemInfo?.price.brand.name || 'unknown')
    apiQryStr += '&prod_tienda=' + (localStorage.getItem('click_origin') || 'directo')
    apiQryStr += '&modulo_tipo=' + encodeURIComponent(this.$route.query.org)
    apiQryStr += '&card_posicion=' + encodeURIComponent(this.$route.query.pos)
    apiQryStr += '&card_cupon=' + (trackEvent || 'unknown')
    apiQryStr += `&so=${so}&browser=${browser}&device=${device}`
    apiQryStr += `&featured=${this.itemInfo?.featured || '0'}`
    apiQryStr += `&url_raiz=${this.itemInfo?.url ? this.$config.domain + this.itemInfo?.url : 'unknown'}`

    this.startTracking = new Date().getTime()
    this.$axios.get(apiBaseUrl + '?' + apiQryStr, { withCredentials: true }).then((result) => {
      this.tracking = result.data
      const endTracking = new Date().getTime()
      const waitTime = (this.itemInfo?.coupon ? 4000 : 1500) - (endTracking - this.startTracking)

      if (waitTime > 0) {
        this.timerRedirect = setTimeout(() => this.autoRedirect(result.data.url), waitTime)
      } else {
        this.autoRedirect(result.data.url)
      }
    })
  },
  methods: {
    autoRedirect (url) {
      window.location.replace(url)
    },
    manualRedirect (url) {
      if (this.timerRedirect) {
        clearTimeout(this.timerRedirect)
      }
      window.location.replace(url)
    },
    formatTiempo (unixTimestamp) {
      const rtf = new Intl.RelativeTimeFormat(project.loc_code, { numeric: 'auto' })
      const now = new Date()
      const past = new Date(unixTimestamp * 1000)

      const secondsDiff = Math.floor((past - now) / 1000)
      const minutesDiff = Math.floor(secondsDiff / 60)
      const hoursDiff = Math.floor(minutesDiff / 60)
      const daysDiff = Math.floor(hoursDiff / 24)

      if (daysDiff !== 0) {
        return rtf.format(daysDiff, 'day')
      } else if (hoursDiff !== 0) {
        return rtf.format(hoursDiff, 'hour')
      } else if (minutesDiff !== 0) {
        return rtf.format(minutesDiff, 'minute')
      } else {
        return rtf.format(secondsDiff, 'second')
      }
    },
    extractCode (text) {
      const partes = text.split(':')
      return partes.length > 1 ? partes[1].trim() : text.trim()
    },
    copyClipboard (copyText) {
      const code = this.extractCode(copyText)
      navigator.clipboard.writeText(code)
        .then(() => {
          this.copiedCoupon = code
          setTimeout(() => {
            this.copiedCoupon = ''
          }, 3000)
        })
    },
    popoverContent (copyText) {
      const code = this.extractCode(copyText)
      return this.copiedCoupon === code
        ? this.$t('price_card.code_copied')
        : this.$t('price_card.click_to_copy')
    }
  }
}
