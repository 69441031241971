//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import { openAuthRequired, applyAuthAction } from '~/lib/auth'
export default {
  mixins: [global],
  props: {
    button: {
      type: String,
      default: () => 'default'
    },
    modal: {
      type: Boolean,
      default: () => true
    },
    product: {
      type: [Number, String],
      default: () => 0
    },
    productCat: {
      type: [Number, String],
      default: () => 0
    }
  },
  data () {
    return {
      step: 1,
      newQuestion: {
        section: {},
        text: '',
        suscription: true
      },
      successMsg: '',
      close: { alt: 'close', src: require('@/assets/img/icons/icon-close-white.svg'), width: 12, heigth: 7 },
      up: { alt: 'open', src: require('@/assets/img/icons/icon-chevron-up-primary.svg'), width: 12, heigth: 7 },
      left: { alt: 'left', src: require('@/assets/img/icons/icon-arrow-back.svg'), width: 12, heigth: 7 },
      sections: [],
      isSubmitting: false
    }
  },
  async fetch () {
    if (this.modal) {
      const sectQry = this.$config.static + '/json/questions/sections.json'
      const sectCode = this.$md5(sectQry)
      if (typeof this.$store.state.lists[sectCode] === 'undefined' || Object.keys(this.$store.state.lists[sectCode]).length === 0) {
        await this.$store.dispatch('loadLists', sectQry)
      }
      this.sections = this.$store.state.lists[sectCode].filter((section) => {
        return section.active === '1'
      })
    }
  },
  mounted () {
    applyAuthAction.apply(this)
  },
  methods: {
    addChat () {
      openAuthRequired.apply(this, [null, {
        name: 'showModal'
      }, 'modal', 'forumChat'])
    },
    showModal () {
      this.$bvModal.show('NewChtMdl')
    },
    hideModal () {
      this.$bvModal.hide('NewChtMdl')
    },
    async submitQuestion () {
      this.isSubmitting = true;
      const bodyFormData = new FormData()
      bodyFormData.append('section_id', this.newQuestion.section.content_id)
      bodyFormData.append('suscription', this.newQuestion.suscription ? 1 : 0)
      bodyFormData.append('data[text]', this.newQuestion.text)
      bodyFormData.append('product', this.product + '_' + this.productCat)
      try {
        await this.$axios.post(this.$config.api + '/app/v1/users/chats', bodyFormData, {
          headers: {
            Authorization: this.$auth.strategy.token.get()
          }
        }).then((resp) => {
          this.isSubmitting = false
          if (resp.status === 200) {
            this.hideModal()
            this.$refs['succes-modal'].show()
            this.successMsg = `${this.$t('newChatButton.thanks')}`
          } else {
            this.hideModal()
            this.$refs['succes-modal'].show()
            this.successMsg = `${this.$t('newChatButton.try_again')}`
          }
        })
      } catch (error) {
        this.hideModal()
        this.isSubmitting = false
        this.$refs['succes-modal'].show()
        this.successMsg = `${this.$t('newChatButton.try_again')}`
      }
    }
  }
}
