//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    val: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // slider
      min: 1,
      max: 10,
      localVal: this.val,
      // IMAGENES
      plus: { alt: 'close', src: require('@/assets/img/icons/icon-plus.svg'), width: 11, heigth: 11 },
      minus: { alt: 'open', src: require('@/assets/img/icons/icon-minus-primary.svg'), width: 11, heigth: 11 }
    }
  },
  methods: {
    change (obj) {
      this.localVal = obj.newValue
    },
    increase () {

    },
    decrease () {

    }
  }
}
