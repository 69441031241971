//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
export default {
  mixins: [global],
  data() {
    return {
      storeData: {},
      product: {},
      prices: {}
    }
  },
  computed: {
    storeLogo() {
      return this.storeData?.logo?.mediano?.src || this.storeData?.logo?.src || ''
    }
  },
  methods: {
    showModal(storeData, product, prices) {
      if (storeData) {
        this.storeData = storeData
        this.product = product
        this.prices = prices
        this.$nextTick(() => {
          this.$bvModal.show('StoreInfoModal')
        })
      }
    },
    hideModal () {
      this.$bvModal.hide('StoreInfoModal')
    },
    getBestPrice() {
      return this.prices.highlight.discount_price
        ? (this.prices.highlight.discount_price.is_coupon ?? 0) === 1
          ? this.prices.highlight.discount_price.price
          : this.prices.highlight.price
        : this.prices.highlight.price;
    },
    openOffer() {
      console.log(this.prices)
      console.log(this.setPriceLink(this.prices.highlight, 1, 'Mejor precio', ''))
      this.openNewTab(this.setPriceLink(this.prices.highlight, 1, 'Mejor precio', ''))
    }
  }
}
