var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"clas":"PgePrcLst"}},[_c('b-container',[_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('AppBreadcrumb',{attrs:{"breadcrumb":_vm.breadcrumb}})],1),_vm._v(" "),(_vm.zones && _vm.zones.GlobalData.zones.ABC_0)?_c('AppDynamicComponent',{attrs:{"items":_vm.zones.GlobalData.zones.ABC_0}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"main-content"},[(_vm.withheader && _vm.zones && _vm.zones.GlobalData.zones.ABC_1)?[_c('LazyHydrate',{attrs:{"when-visible":""}},[(_vm.zones && _vm.zones.GlobalData.zones.ABC_1)?_c('AppDynamicComponent',{staticClass:"col-12",attrs:{"items":_vm.zones.GlobalData.zones.ABC_1}}):_vm._e()],1)]:[_c('br')],_vm._v(" "),_c('h1',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.capitalize(_vm.header.h1)))]),_vm._v(" "),_c('div',{staticClass:"list"},[_c('b-row',[_c('b-col',{attrs:{"lg":"3","order":"2","order-lg":"1"}},[(_vm.prices.filters)?_c('ListFilters',{attrs:{"type":"prices","filters":_vm.prices.filters,"config":_vm.productType,"total":_vm.prices.total,"text-search":{ active: true, text: _vm.querySearch }}}):_vm._e(),_vm._v(" "),(_vm.zones && _vm.zones.GlobalData && _vm.zones.GlobalData.zones.C_1)?_c('AppDynamicComponent',{staticClass:"sidebar d-lg-block mt-5",attrs:{"items":_vm.zones.GlobalData.zones.C_1,"zone":'C_1'}}):_vm._e()],1),_vm._v(" "),_c('b-col',{attrs:{"lg":"9","order":"1","order-lg":"2"}},[(_vm.prices.contents && _vm.prices.contents.length > 0)?_c('div',{staticClass:"total-shown"},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.prices.total))]),_vm._v("\n                "+_vm._s(_vm.$t('price_list.prices_of_product_type', {
                  type: _vm.productType.name,
                }).toLowerCase())+"\n                "+_vm._s(_vm.$t('price_list.of'))+"\n                "),_c('strong',[_vm._v(_vm._s(_vm.storesTotal))]),_vm._v("\n                "+_vm._s(_vm.$t('price_list.shops').toLowerCase())+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"sort"},[_c('b-input-group',[_c('b-input-group-prepend',[_c('span',[_vm._v(_vm._s(_vm.$t('price_list.order_by')))])]),_vm._v(" "),_c('b-form-select',{attrs:{"id":"sort-by","options":_vm.sorting,"required":""},on:{"change":function($event){return _vm.changeOrder(_vm.order)}},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}})],1)],1)]):_vm._e(),_vm._v(" "),(_vm.prices.filters.view)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"d-flex flex-wrap",attrs:{"lg":"12"}},[_vm._l((_vm.filters),function(filter){return [_vm._l((filter.values),function(data){return [(data.active === 1 && data.finalUrl)?[(data.nofollow)?_c('span',{key:data.id,staticClass:"enlaceofuscadoPills active",on:{"click":function($event){_vm.storeFilters(
                            filter.id,
                            data.id,
                            _vm.prices.filters.url,
                            _vm.productType,
                            'prices',
                            '',
                            data.active,
                            _vm.prices.filters.highlight &&
                              _vm.prices.filters.highlight.includes(filter.id)
                              ? true
                              : false,
                            false,
                            _vm.prices.filters,
                            _vm.$route.query
                          ),
                          function (event) { return event.preventDefault(); }}}},[_vm._v("\n                        "+_vm._s(_vm.getFilterValSeo(filter.id, data.id, _vm.productType))+" X\n                      ")]):_c('nuxt-link',{key:data.id,staticClass:"enlaceofuscadoPills active",attrs:{"to":data.finalUrl},nativeOn:{"click":function($event){_vm.storeFilters(
                            filter.id,
                            data.id,
                            _vm.prices.filters.url,
                            _vm.productType,
                            'prices',
                            '',
                            data.active,
                            _vm.prices.filters.highlight &&
                              _vm.prices.filters.highlight.includes(filter.id)
                              ? true
                              : false,
                            false,
                            _vm.prices.filters,
                            _vm.$route.query
                          ),
                          function (event) { return event.preventDefault(); }}}},[_vm._v("\n                        "+_vm._s(_vm.getFilterValSeo(filter.id, data.id, _vm.productType))+" X\n                      ")])]:_vm._e()]})]})],2)],1):_vm._e(),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[(_vm.prices.contents && _vm.prices.contents.length > 0)?_c('b-card-group',{staticClass:"grid py-4",attrs:{"deck":""}},_vm._l((_vm.prices.contents),function(price,i){return _c(price.type,{key:i,tag:"Component",attrs:{"price":price,"pos":i + 1,"org":_vm.productType.marketplace ? 'Marketplace' : 'Outlet',"at":_vm.prices.test,"elements":{
                    brand: true,
                    name: true,
                    photo: true,
                    price: true,
                    pvp: true,
                  },"w":300}})}),1):[_c('PriceNotFound',{attrs:{"type":_vm.productType.name,"pid":_vm.productType.paths.home,"path":_vm.outletPath}})]],2),_vm._v(" "),_c('LazyHydrate',{attrs:{"on-interaction":"click"}},[_c('ListPagination',{attrs:{"current-page":_vm.prices.pagination.page,"total-items":_vm.prices.total,"items-per-page":_vm.prices.pagination.contents,"follow":false}})],1),_vm._v(" "),(_vm.extraModulesBottom && _vm.extraModulesBottom.length > 0)?_c('div',{staticClass:"py-4",attrs:{"id":"marca-2-usuarios"}},_vm._l((_vm.extraModulesBottom),function(item,ind){return _c(item.template,{key:ind,tag:"component",staticClass:"pt-2",attrs:{"data":item.data,"elements":item.elements,"generic":item.generic,"type":item.type}})}),1):_vm._e()],1)],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }